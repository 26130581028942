<template>
  <v-container class="py-0 my-4">
      <datetime-picker
        placeholder="選擇時間區間"
        range
        v-model="value"
        hide-details
        dense
        clearable
      ></datetime-picker>
      <!-- <v-btn color="primary" class="ml-2">篩選</v-btn> -->
  </v-container>
</template>

<script>
import itemMixins from "@/components/liffList/listFilter/item/item.js";
export default {
  mixins: [itemMixins],
  props: {
    filterValue: {
      type: Array,
    },
  },
  components: {},
  data: () => ({}),
  computed: {},
  watch: {
    filterValue: {
      immediate: true,
      async handler() {
        this.value = this.$eagleLodash.cloneDeep(this.filterValue);
      },
    },
    value: {
      deep: true,
      handler() {
        if (this.$eagleLodash.isEqual(this.value, this.filterValue)) return;
        if (this.$eagleLodash.isEmpty(this.value)) {
          this.setValue(null);
          return;
        }

        let empty = true;
        for (let i = 0; i < this.value.length; i++) {
          const item = this.value[i];
          if (item) {
            empty = false;
            break;
          }
        }

        if (empty) {
          this.setValue(null);
          return;
        }

        this.setValue(this.value);
      },
    },
  },
  methods: {
    async setValue(value) {
      this.value = value;
      this.$root.$emit(`start-liff-list-filter`);
    },
  },
};
</script>